import { MESSAGE_TYPE } from '../constants';

export default function getFetchMessage({
  validationFeedback,
  errorFeedback,
  fetchValidation,
  fetchError,
  available = true
}: {
  validationFeedback: string;
  errorFeedback: string;
  fetchValidation: boolean;
  fetchError?: string | null;
  available?: boolean;
}) {
  if (!available) {
    return {
      text: errorFeedback,
      type: MESSAGE_TYPE.LOCKED
    };
  }

  if (fetchValidation) {
    return {
      text: validationFeedback,
      type: MESSAGE_TYPE.SUCCESS
    };
  }

  if (fetchError) {
    return {
      text: fetchError,
      type: MESSAGE_TYPE.ERROR
    };
  }

  return undefined;
}
