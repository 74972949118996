import { useMutation } from 'react-query';

import { SelfcareApis } from 'shared/modules/page/selectors/getSelfcareApis';
import getNumSubscriber from 'client/helpers/getNumSubscriber';
import abstractWebservice, {
  AbstractResponse
} from '../webservices/abstractWebservice';

const FETCH_SUCCESS_CODES = ['0'];

const useReactivateImageMutation = (
  apis: Pick<SelfcareApis, 'reactivateImage'>,
  numContract: string
) => {
  const numSubscriber = getNumSubscriber();

  return useMutation<AbstractResponse, string>(() =>
    abstractWebservice<AbstractResponse>(
      apis.reactivateImage,
      { successCodes: FETCH_SUCCESS_CODES },
      { params: { numSubscriber, numContract } }
    )
  );
};

export default useReactivateImageMutation;
