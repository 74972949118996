import * as React from 'react';
import styled from 'styled-components';

import Raw from 'shared/components/presentational/Raw/Raw';
import Button from 'shared/components/presentational/Button/Button';
import Icon from 'shared/components/presentational/Icons';
import { MESSAGE_TYPE, MESSAGE_TYPE_VALUES } from '../constants';
import getIconNameFromMessageType from '../helpers/getIconNameFromMessageType';
import { FONT_FAMILIES, FONT_WEIGHTS } from 'shared/constants/theme';
import Title from 'shared/components/presentational/Title';
import pxToRem from 'shared/helpers/styled-components/remHelper';
import { Agreement } from '../types';
import Radios from 'shared/components/presentational/Form/Radios';

const Wrapper = styled.div`
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 32px;
`;

const ButtonContainer = styled.div`
  text-align: center;
`;

const StyledIcon = styled(Icon)`
  margin-right: 10px;
`;

const FeedbackContainer = styled.div<{
  type?: MESSAGE_TYPE_VALUES;
}>`
  max-width: 1000px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: ${props => {
    switch (props.type) {
      case MESSAGE_TYPE.SUCCESS:
        return 'var(--surface-success)';
      case MESSAGE_TYPE.LOCKED:
        return 'var(--surface-info)';
      case MESSAGE_TYPE.ERROR:
        return 'var(--surface-error)';
      default:
        break;
    }
    return null;
  }};
  margin: 0 auto;
`;

const StyledButton = styled(Button)`
  margin-top: 6px;
  flex-grow: 0;
  border-radius: var(--border-radius-m);
  font-size: 16px;
`;

const SubTitle = styled.h3`
  color: var(--text-title);
  font-size: ${pxToRem(16)};
  font-family: ${FONT_FAMILIES.brand};
  font-weight: ${FONT_WEIGHTS.bold};
  padding: 0;
  margin-bottom: 16px;
`;

export const RadiosWrapper = styled.div`
  margin-bottom: 24px;
  label {
    font-size: 16px;
  }
`;

interface Props {
  title?: string;
  titleHtmlTag?: string;
  description: string;
  subtitle?: string;
  buttonLabel: string;
  buttonDisabled?: boolean;
  buttonPending?: boolean;
  onButtonClick?: () => void;
  message?: {
    type: MESSAGE_TYPE_VALUES;
    text: string;
  };
  onAgreementChange?: React.ChangeEventHandler<HTMLInputElement>;
  checkboxValue?: string;
  agreements?: Agreement[];
}

export default function SelfcareSetting(props: Props) {
  const {
    title,
    titleHtmlTag,
    description,
    subtitle,
    buttonLabel,
    buttonDisabled,
    buttonPending,
    onButtonClick: handleButtonClick,
    message,
    onAgreementChange,
    checkboxValue,
    agreements
  } = props;

  const renderAgreements = React.useCallback(() => {
    if (agreements && agreements.length > 0) {
      const options = agreements.map(agreement => ({
        label: agreement.label,
        value: agreement.value
      }));
      return (
        <RadiosWrapper id="agreements">
          <Radios
            name="agreements"
            value={checkboxValue ?? ''}
            onChange={onAgreementChange}
            options={options}
            isRequired={false}
            phoneHorizontal={true}
          />
        </RadiosWrapper>
      );
    }
    return null;
  }, [agreements, checkboxValue, onAgreementChange]);

  return (
    <Wrapper>
      {title && title.length > 0 ? (
        <Title titleLabel={title} titleHtmlTag={titleHtmlTag} />
      ) : null}
      {subtitle && subtitle.length > 0 ? <SubTitle>{subtitle}</SubTitle> : null}

      {renderAgreements()}

      <Raw html={description} />
      <ButtonContainer>
        <StyledButton
          variant="primary"
          onClick={handleButtonClick}
          disabled={buttonDisabled}
          loading={buttonPending}
        >
          <Button.children.Text>{buttonLabel}</Button.children.Text>
        </StyledButton>
      </ButtonContainer>
      {message && (
        <FeedbackContainer type={message.type}>
          <StyledIcon
            width="20"
            height="20"
            name={getIconNameFromMessageType(message.type)}
          />
          {message.text}
        </FeedbackContainer>
      )}
    </Wrapper>
  );
}
