import { useQuery } from 'react-query';

import { SelfcareApis } from 'shared/modules/page/selectors/getSelfcareApis';
import getNumSubscriber from 'client/helpers/getNumSubscriber';
import { QUERY_KEYS } from '../constants';
import abstractWebservice, {
  AbstractResponse
} from '../webservices/abstractWebservice';

const FETCH_SUCCESS_CODES = ['0'];

const useCanSendReacCommandQuery = (
  apis: Pick<SelfcareApis, 'canSendReacCommand'>,
  numContract: string
) => {
  const numSubscriber = getNumSubscriber();

  return useQuery<AbstractResponse & { result: string }, string>(
    [QUERY_KEYS.CAN_SEND_REAC_COMMAND_BY_NUM_CONTRACT, numContract],
    () => {
      return abstractWebservice<AbstractResponse & { result: string }>(
        apis.canSendReacCommand,
        {
          successCodes: FETCH_SUCCESS_CODES,
          customErrorConditions: [data => data?.result !== '1']
        },
        { params: { numContract, numSubscriber } }
      );
    },
    { enabled: !!numContract }
  );
};

export default useCanSendReacCommandQuery;
