import { ICON_NAMES } from 'shared/components/presentational/Icons';
import { MESSAGE_TYPE } from '../constants';

export default type => {
  switch (type) {
    case MESSAGE_TYPE.SUCCESS:
      return ICON_NAMES.CHECK;
    case MESSAGE_TYPE.LOCKED:
      return ICON_NAMES.SECURE;
    case MESSAGE_TYPE.ERROR:
    default:
      return ICON_NAMES.WARNING;
  }
};
