import { connect } from 'react-redux';
import { compose } from 'recompose';

import getDevices from 'shared/modules/page/selectors/getDevices';
import ImageReactivation from '../components/ImageReactivation';
import getNumContract from '../../shared/selectors/getNumContract';
import { FormattedProps, PropsFromState, Props } from '../types';
import withSubscriberDataQuery from '../../../new-selfcare/shared/hocs/withSubscriberDataQuery';
import withCanSendReacCommandQuery from '../../../new-selfcare/shared/hocs/withCanSendReacCommandQuery';
import withReactivateImageMutation from '../../../new-selfcare/shared/hocs/withReactivateImageMutation';
import withContextConditions from 'shared/blocks/new-selfcare/shared/hocs/withContextConditions';

const mapStateToProps = (state: any): PropsFromState => {
  const numContract = getNumContract(state);
  const devices = getDevices(state);

  return {
    numContract,
    devices
  };
};

export default compose<Props, FormattedProps>(
  connect(mapStateToProps),
  withSubscriberDataQuery,
  withContextConditions,
  withCanSendReacCommandQuery,
  withReactivateImageMutation
)(ImageReactivation);
