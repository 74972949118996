import styled from 'styled-components';

import mediaQueries from 'shared/helpers/styled-components/mediaQueries';

const EmpytWrapper = styled.section`
  padding: 40px 20px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;

  ${mediaQueries.toTablet`
    max-width: 100%;
    margin-left: 15px;
    margin-right: 15px;
  `};
`;

export default EmpytWrapper;
