import React, { ComponentType } from 'react';

import { getBlockConditionBehaviour } from 'shared/blocks/selfcare/shared/helpers/getBlockConditionBehaviour';
import {
  ContextCriteria,
  SubscriberData
} from 'shared/blocks/selfcare/shared/types';
import { Contract } from 'shared/blocks/new-selfcare/shared/hooks/useSubscriberDataQuery';
import { CONTEXT_BEHAVIOURS, CONTEXT_CONDITIONS } from '../constants';
import Logger from 'shared/helpers/logger/getLogger';

//should be placed before other hocs in the container to avoid useless api calls when the block is hidden
export default function withContextCondition<
  T extends {
    contextCriteria: ContextCriteria[] | null;
    subscriberData: SubscriberData & { isFetched: boolean };
    numContract: string | null;
  }
>(Component: ComponentType<T>) {
  return (props: T) => {
    const {
      contextCriteria,
      subscriberData: { isFetched, data },
      numContract
    } = props;

    const selectedContract = React.useMemo(() => {
      if (!numContract) {
        return null;
      } else if (isFetched && data) {
        return data.listOfContracts.find(
          (contract: Contract) => contract.numContract === numContract
        );
      }
    }, [numContract, isFetched, data]);

    const contextConditions = React.useMemo(() => {
      const errorCodeCriteria =
        contextCriteria &&
        contextCriteria.find(
          criteria => criteria.condition === CONTEXT_CONDITIONS.ERROR_CODE
        );

      const otherCriterias =
        selectedContract && contextCriteria
          ? getBlockConditionBehaviour(contextCriteria, selectedContract)
          : [];

      return errorCodeCriteria
        ? [
            ...otherCriterias,
            {
              condition: data?.errorCode === errorCodeCriteria.contextValue,
              behavior: errorCodeCriteria.expectedBehaviour
            }
          ]
        : otherCriterias;
    }, [contextCriteria, selectedContract, data]);

    const hideBlockCriteria = React.useMemo(
      () =>
        !isFetched ||
        contextConditions?.some(
          condition =>
            condition?.behavior === CONTEXT_BEHAVIOURS.HIDE &&
            condition?.condition === true
        ) ||
        (!contextConditions && contextCriteria),
      [contextConditions, contextCriteria, isFetched]
    );

    Logger.info('context conditions', contextConditions);

    if (hideBlockCriteria) {
      return null;
    } else {
      return <Component {...props} />;
    }
  };
}
