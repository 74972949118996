import React from 'react';
import { useTranslation } from 'react-i18next';

import EmptyWrapper from 'shared/blocks/selfcare/shared/components/EmptyWrapper';
import isMobile from 'shared/helpers/devices/isMobile';
import isWebapp from 'shared/helpers/devices/isWebapp';
import { getFetchMessage } from '../../shared/helpers';
import SelfcareSetting from 'shared/blocks/selfcare/shared/components/SelfcareSetting';
import { Props } from '../types';
import Error from 'shared/components/presentational/Error/Error';
import Loader from '../../shared/components/Loader';
import { Wrapper, ErrorWrapper } from '../styles/components';

/*
 * Main wrapper should be a section
 * in order to inherit margins
 */

export default function ImageReactivation({
  devices,
  validationFeedback,
  errorFeedback,
  buttonLabel,
  subscriberData: { error: subscriberDataError },
  canSendReacCommand: {
    data: canSendReacCommand,
    error: canSendReacError,
    isLoading: canSendReacLoading,
    isIdle: canSendReactIdle
  },
  reactivateImage: {
    mutate: reactivateImage,
    isLoading: reactivationLoading,
    isSuccess: reactivationSuccess,
    error: reactivationError
  },
  ...props
}: Props) {
  const { t } = useTranslation();
  const canSendReacValidation = canSendReacCommand?.result === '1';

  const isDeviceMobile = isMobile(devices);
  const isDeviceWebapp = isWebapp(devices);

  const handleButtonClick = () => {
    if (!reactivationSuccess && reactivateImage) {
      reactivateImage();
    } else {
      if (reactivationSuccess) {
        if (isDeviceWebapp) {
          location.replace('https://www.canalplus.com');
          return;
        }

        if (isDeviceMobile) {
          location.replace('pass://closeWebView');
        }
      }
    }
  };

  if (subscriberDataError?.prospect) {
    return <EmptyWrapper />;
  }

  if (canSendReactIdle || canSendReacLoading) {
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  }

  if (canSendReacError) {
    return (
      <ErrorWrapper>
        <Error />
      </ErrorWrapper>
    );
  }

  let formattedButtonLabel = buttonLabel;

  if (reactivationSuccess && (isDeviceMobile || isDeviceWebapp)) {
    formattedButtonLabel = t('components.webviewCloseButton.close');
  }

  const message = getFetchMessage({
    validationFeedback,
    errorFeedback,
    fetchValidation: !!reactivationSuccess,
    fetchError: reactivationError,
    available: canSendReacValidation
  });

  const buttonEnabled =
    !canSendReacValidation &&
    (reactivationSuccess || !isDeviceMobile || !isDeviceWebapp);

  return (
    <Wrapper>
      <SelfcareSetting
        buttonDisabled={buttonEnabled}
        onButtonClick={handleButtonClick}
        buttonPending={reactivationLoading}
        buttonLabel={formattedButtonLabel}
        message={message}
        {...props}
      />
    </Wrapper>
  );
}
