import styled from 'styled-components';

import mediaQueries from 'shared/helpers/styled-components/mediaQueries';
import { FONT_FAMILIES } from 'shared/constants/theme';

export const Wrapper = styled.section`
  padding: 0px 40px 20px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;

  ${mediaQueries.toTablet`
    max-width: 100%;
    padding: 0px 20px 20px;
    margin-left: 15px;
    margin-right: 15px;
  `};

  background-color: var(--surface-primary);
  font-family: ${FONT_FAMILIES.base};
`;

export const ErrorWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;
