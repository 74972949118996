import React, { ComponentType } from 'react';

import { SelfcareApis } from 'shared/modules/page/selectors/getSelfcareApis';
import useReactivateImage from '../hooks/useReactivateImageMutation';

export interface WithReactivateImageMutationProps {
  reactivateImage: Partial<ReturnType<typeof useReactivateImage>>;
}

export default function withReactivateImageMutation<
  T extends {
    apis: Pick<SelfcareApis, 'reactivateImage'>;
    numContract: string;
  }
>(Component: ComponentType<T>) {
  return (props: T) => {
    const reactivateImage = useReactivateImage(props.apis, props.numContract);

    return <Component reactivateImage={reactivateImage} {...props} />;
  };
}
