import { ContextCriteria } from '../types';
import { Contract } from 'shared/blocks/new-selfcare/shared/hooks/useSubscriberDataQuery';
import { CONTEXT_CONDITIONS } from 'shared/blocks/new-selfcare/shared/constants';

export const getBlockConditionBehaviour = (
  contextCriteria: ContextCriteria[],
  contract: Contract
) =>
  contextCriteria.map(({ condition, contextValue, expectedBehaviour }) => {
    switch (condition) {
      case CONTEXT_CONDITIONS.BROADCASTING_WAY: {
        const conditionResult = contract.broadcastingWay === contextValue;
        return {
          condition: conditionResult,
          behavior: expectedBehaviour
        };
      }
      // rules might change for different frontDisplayCriteria
      case CONTEXT_CONDITIONS.IS_ACTIVE: {
        const conditionResult = contract.indActive === contextValue;

        return {
          condition: conditionResult,
          behavior: expectedBehaviour
        };
      }

      default:
        return null;
    }
  });
