import React, { ComponentType } from 'react';

import { SelfcareApis } from 'shared/modules/page/selectors/getSelfcareApis';
import useCanSendReacCommand from '../hooks/useCanSendReacCommandQuery';

export interface WithCanSendReacCommandProps {
  canSendReacCommand: Partial<ReturnType<typeof useCanSendReacCommand>>;
}

export default function withCanSendReacCommandQuery<
  T extends {
    apis: Pick<SelfcareApis, 'canSendReacCommand'>;
    numContract: string;
  }
>(Component: ComponentType<T & WithCanSendReacCommandProps>) {
  return (props: T) => {
    const canSendReacCommand = useCanSendReacCommand(
      props.apis,
      props.numContract
    );

    return <Component canSendReacCommand={canSendReacCommand} {...props} />;
  };
}
